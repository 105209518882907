<template>
	<div class="layout">
		<b-header
			border
			platform
		>
		</b-header>

		<div class="content">
			<div v-if="$windowWidth > 767" class="projects__container self-scroll__container">
				<b-projects class="projects__item self-scroll__item"/>
			</div>
			<main class="project self-scroll__container">
				<b-invite v-if="projects.length == 0" full-screen/>
				<b-game v-else class="self-scroll__item" :key="this.$route.params.gameId"></b-game>
			</main>
		</div>
	</div>
</template>

<script>
import header from "@/components/header.vue"
import projects from "@/components/projects.vue"
import invite from "@/components/invite"
import game from "@/components/game"

export default {
	name: 'games-view',
	components: {
		"b-header": header,
		"b-projects": projects,
		"b-invite": invite,
		"b-game": game
	},
	computed: {
		projects(){
			return this.$store.getters['projects/all']
		}
	}
}
</script>

<style scoped lang="sass">

.layout
	min-height: 100%
	display: flex
	flex-direction: column

.content
	flex: 1 0
	display: flex

.project
	padding: 23px 40px 0 33px
	flex: 1 0

.self-scroll__container
	position: relative

.self-scroll__item
	overflow-y: auto
	overflow-x: hidden
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0

.projects__container
	display: none
	width: 100%

@media screen and (min-width: $sm) 
	.project
		display: block

	.projects__container
		display: block
		width: 336px

@media screen and (min-width: $md) 
	.projects__container
		width: 407px


</style>